import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { EventRegistrationService } from '../../services/event-registration.service';
import { getCongressLandingPageData, getCongressMetaData } from '../../../core/data/selectors/data-api.selectors';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../core/store/reducers';
import { SESSION_UTILS_TOKEN } from '../../utilities/session.utils';
import { CongressUtils } from '../../utilities/congress.utils';
import { getCongressParticipantData } from '../../../../medtoday/src/app/medtoday-store/selectors/medtoday-store.selectors';
import { imagePaths } from '../../../../medtoday/src/environments/environment';
import { BehaviorSubject, tap } from 'rxjs';
import { IS_MED_TODAY_APP, IS_PATIENTS_TODAY_APP } from '../../../core/definitions/app.definitions';
import { isLoggedIn } from '../../../core/auth/selectors/auth.selectors';
import { CongressMetadata } from 'medtoday-models-library';
import { ICalendar } from 'datebook';

@Component({
  selector: 'app-event-page-header',
  templateUrl: './event-page-header.component.html',
  styleUrls: ['./event-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EventRegistrationService, CongressUtils]
})
export class EventPageHeaderComponent implements OnInit {
  congressLandingPageData$ = this.store.select(getCongressLandingPageData);
  congressMetaData$ = this.store.select(getCongressMetaData);
  congressParticipantData$ = this.store.select(getCongressParticipantData);
  logosImageFolderSlug = imagePaths.logos;
  isLoggedIn$ = this.store.select(isLoggedIn);
  showSignUpButton = false;
  @Input() congressTimeRange: string;
  @Input() isAnySessionLive$: BehaviorSubject<boolean>;
  @Input() liveSession;
  @Input() isMultiChannel$: BehaviorSubject<boolean>;

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    protected store: Store<BaseAppState>,
    @Inject(SESSION_UTILS_TOKEN) public sessionUtils,
    public registrationService: EventRegistrationService,
    public congressUtils: CongressUtils,
    @Inject(IS_MED_TODAY_APP) public isMedTodayApp: string,
    @Inject(IS_PATIENTS_TODAY_APP) public isPatientsTodayApp: string
  ) {}

  ngOnInit() {
    this.signUpButtonVisibility();
  }

  handleGoToProgramClick(): void {
    const el = document.getElementById('programme-section');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  signUpButtonVisibility() {
    this.congressMetaData$
      .pipe(
        tap(congressMetaData => {
          if (
            !this.congressUtils.isAfterCongress(congressMetaData) &&
            !this.registrationService.isUserAuthorized &&
            congressMetaData.authorizedOnly &&
            this.registrationService.showSignUpBtn
          ) {
            const isOnSiteClosed =
              congressMetaData.congressCategory === 'ON_SITE' && congressMetaData.offlineRegistrationClosed;

            const isHybridClosed =
              congressMetaData.congressCategory === 'HYBRID' &&
              congressMetaData.offlineRegistrationClosed &&
              congressMetaData.onlineRegistrationClosed;

            const isOnlineClosed =
              congressMetaData.congressCategory === 'ONLINE' && congressMetaData.onlineRegistrationClosed;

            this.showSignUpButton = !(isOnSiteClosed || isHybridClosed || isOnlineClosed);
          } else {
            this.showSignUpButton = false;
          }
        })
      )
      .subscribe();
  }

  handleAddToCalendarClick(congressMetaData: CongressMetadata) {
    const icalendar = new ICalendar(
      this.congressUtils.createCalendarEvent(this.congressUtils.createCalendarEventItem(congressMetaData, undefined))
    );
    icalendar.download('event.ics');
  }

  handleGoToCongressBag(buttonUrl: string) {
    if (buttonUrl) {
      window.open(buttonUrl, '_blank');
      return;
    }
  }

  scrollToElement(element): void {
    const anchor = document.querySelector(element);
    const offset = 100;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = anchor.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
}
