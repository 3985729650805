import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { BaseAppState } from '../../store/reducers';
import { isLoggedIn } from '../selectors/auth.selectors';
import { ElearningService } from '../../../shared/modules/e-learning/services/elearning.service';
import { environment } from '../../../../medtoday/src/environments/environment';
import { MAIN_NAVIGATION_PATH_ELEMENTS } from '../../router/definitions/main-navigation.definitions';
import { getCongressSlugRouterParam, getOnDemandVideoIdRouterParam } from '../../router/selectors/router.selectors';
import moment from 'moment';
import { ToastService } from '../../toasts/services/toast.service';

@Injectable()
export class ELearningBlacklistedGuardService implements CanActivate {
  isLoggedIn$ = this.store.select(isLoggedIn);
  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  onDemandVideoId$ = this.store.select(getOnDemandVideoIdRouterParam);

  constructor(
    private router: Router,
    private store: Store<BaseAppState>,
    private eLearningService: ElearningService,
    private toastService: ToastService
  ) {}

  canActivate(): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (environment.language !== 'de') {
      return of(true);
    }

    return combineLatest([this.onDemandVideoId$, this.congressSlug$]).pipe(
      switchMap(([vodId, congressSlug]) =>
        this.eLearningService.getElearningMetadata(vodId).pipe(
          map(metadata => {
            if (moment.utc(metadata.blacklistedUntil).isAfter(moment.utc())) {
              this.toastService.showError('', "You can't do e-learning right now.", 3000, true, {
                positionClass: 'toast-top-center'
              });
              return this.router.createUrlTree([
                MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement,
                congressSlug,
                MAIN_NAVIGATION_PATH_ELEMENTS.root.onDemandVideosElement
              ]);
            } else {
              return true;
            }
          })
        )
      )
    );
  }
}
