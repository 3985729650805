import { KNOWLEDGE_SERIES_OPTIONS } from '../../../todaylib/static/knowledge-series/knowledge-series-constants';

export const environment = {
  // version: packageInfo.version,
  production: false,
  apiBaseUrl: ' https://u3lwjso3cl.execute-api.eu-central-1.amazonaws.com/prod',
  // apiBaseUrl: ' http://localhost:3000',
  s3BucketUrl: 'https://patientstoday-stack-de-d-appassetsandcontentbucke-1tv83n1c9gr8l.s3.eu-central-1.amazonaws.com/',
  reactAppScriptUrl: 'https://medtoday-react.de/api/embed-talks-app.js',
  surveyMonkeyScriptUrl:
    'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd1Dvcv88EFVcM0b_2B2kWcWLODPa_2B6LqcU5b_2FT07NH97lz.js',
  name: 'PatientsToday',
  language: 'de',
  cognito: {
    cognitoUserPoolId: 'eu-central-1_NOpYfMKdD',
    cognitoAppClientId: '7fldsslj727hmvg4oo9edd2td4',
    cognitoIdentityPoolId: 'eu-central-1:cbd80844-8a4a-4609-b55a-b2e14304f820',
    appInstanceArn: 'arn:aws:chime:us-east-1:322690393080:app-instance/59412174-026e-4d4a-8335-110656f24589',
    region: 'eu-central-1'
  },
  instagramProfileUrl: 'https://www.instagram.com/patientstoday.de/',
  facebookProfileUrl: 'https://www.facebook.com/patientstoday.de/',
  facebookWallId: '11237',
  megaMenuFilterExclude: 'MedEd',
  youtubeApiKey: 'AIzaSyA-tgNx748ZPoLVGrXTrXTpMi6MfoCuqac',
  knowledgeSeriesVideosIds: {
    [KNOWLEDGE_SERIES_OPTIONS[0]]: [323],
    [KNOWLEDGE_SERIES_OPTIONS[1]]: [323, 324],
    [KNOWLEDGE_SERIES_OPTIONS[2]]: [325]
  }
};

export const imagePaths = {
  logos: 'logo/', // sponsors, patrons, website logos etc.
  thumbnails: 'thumbnail/', // video thumbnails, feature blocks
  avatars: 'avatar/', // profile pictures
  images: 'image/', // general image
  pdfs: 'pdf/'
};
