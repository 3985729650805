import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, map, switchMap, catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { BaseAppState } from '../../store/reducers';
import { isLoggedIn } from '../selectors/auth.selectors';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { Auth } from '@aws-amplify/auth';
import { GoToSignUp } from '../../../../medtoday/src/app/auth-ui/actions/auth-ui-navigation.actions';
import { AuthService } from '../services/auth.service';
import { LoginSuccess } from '../actions/auth.actions';

@Injectable()
export class MissingUserDataResolverGuard implements CanActivate {
  isLoggedIn$ = this.store.select(isLoggedIn);

  constructor(private store: Store<BaseAppState>, private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.isLoggedIn$.pipe(
      take(1),
      switchMap(loggedIn => {
        if (!loggedIn) {
          return fromPromise(this.authService.userHasMissingDataOnStep()).pipe(
            take(1),
            switchMap(incompleteStep => {
              if (!incompleteStep) {
                return fromPromise(Auth.currentAuthenticatedUser()).pipe(
                  take(1),
                  switchMap(user => {
                    return fromPromise(this.authService.setAuthenticatedUser(user)).pipe(
                      map(() => {
                        this.store.dispatch(
                          new LoginSuccess(
                            this.authService.loginResponse$.value.loggedInUser,
                            this.authService.loginResponse$.value.currentMember
                          )
                        );
                        return true;
                      })
                    );
                  }),
                  catchError(() => of(true))
                );
              } else {
                return fromPromise(Auth.currentAuthenticatedUser()).pipe(
                  take(1),
                  map(user => {
                    this.store.dispatch(
                      new GoToSignUp(this.authService.authProviderAttributesToModel(user.attributes), incompleteStep)
                    );
                    return false;
                  })
                );
              }
            })
          );
        } else {
          return of(true);
        }
      })
    );
  }
}
