<ng-container *ngIf="congressMetaData$ | async as congressMetaData">
  <header>
    <img
      *ngIf="congressMetaData.layout.backgroundImage"
      draggable="false"
      [src]="s3BucketUrl + congressMetaData.layout.backgroundImage"
    />
    <div class="container mt-5">
      <div class="row mb-5">
        <div class="col-xl-2 col-lg-3 col-md-4 col-12">
          <app-navigation-menu></app-navigation-menu>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-7 order-lg-1 h-100 mt-4 mb-4 pr-sm-2 mb-3">
          <h1>CME points for attending the LIVE event</h1>
        </div>
      </div>
    </div>
  </header>
</ng-container>

<section id="cme-tutorial">
  <div class="container">
    <h3>Collecting CME points is easy!</h3>
    <div class="row">
      <div class="cme-step col-sm-12 col-md-6">
        <img draggable="false" src="assets/images/CME_step-1.svg" alt="" />
        <h4>1. Activate toggle</h4>
        <p>Activate the toggle button at the top of the navigation to track your participation in the live program.</p>
      </div>
      <div class="cme-step col-sm-12 col-md-6">
        <img draggable="false" src="assets/images/CME_step-3.svg" alt="" />
        <h4>2. Watch program</h4>
        <p>
          Follow the live program.<br />
          The certificate of participation will then be sent to you.
        </p>
      </div>
    </div>
  </div>
</section>
<ng-container *ngIf="congressCmePageData$ | async as congressCmePageData">
  <section id="quickfacts">
    <div class="container">
      <h2>Quickfacts</h2>
      <hr />
      <div class="quickfacts-vnr-container row" *ngIf="congressCmePageData.vnrDescription">
        <div class="col-sm-12 col-md-4">
          <h4>VNR</h4>
        </div>

        <div class="col-sm-12 col-md-8">
          <p [innerHTML]="congressCmePageData.vnrDescription"></p>
        </div>
      </div>

      <div class="quickfacts-program-container row" *ngIf="congressCmePageData.programDescription">
        <div class="col-sm-12 col-md-4">
          <h4>Education credit points</h4>
        </div>

        <div class="col-sm-12 col-md-8">
          <p [innerHTML]="congressCmePageData.programDescription"></p>
        </div>
      </div>

      <div class="quickfacts-scientific-management-container row" *ngIf="congressCmePageData.experts">
        <div class="col-sm-12 col-md-4">
          <h4>Scientific management</h4>
        </div>

        <div class="col-sm-12 col-md-8">
          <p [innerHTML]="congressCmePageData.experts"></p>
        </div>
      </div>

      <div class="quickfacts-speakers-container row">
        <div class="col-sm-12 col-md-4">
          <h4>Speaker</h4>
        </div>
        <div class="col-sm-12 col-md-8">
          <p>
            All speakers of this live event including their conflicts of interest can be found in our
            <a (click)="handleGoToExpertsClick()">overview</a>.
          </p>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<section id="cme-faq">
  <div class="container">
    <h2>Frequently Asked Questions</h2>
    <hr />
    <div class="row">
      <div class="col-12 col-md-8 offset-md-4 mb-4">
        <div id="accordion">
          <div class="card">
            <button
              class="btn collapsed"
              data-toggle="collapse"
              data-target="#faq1"
              aria-expanded="true"
              aria-controls="faq1"
            >
              Do I have to register on medtoday to claim CME credits?
              <div class="indicator"></div>
            </button>
            <div id="faq1" class="collapse" data-parent="#accordion">
              <p>
                Yes, in order to claim CME credits a registration on medtoday is required. You will need to create an
                account with your personal e-mail address and password.
              </p>
            </div>
          </div>
          <div class="card">
            <button
              class="btn collapsed"
              data-toggle="collapse"
              data-target="#faq2"
              aria-expanded="false"
              aria-controls="faq2"
            >
              I forgot my login details. What should I do?
              <div class="indicator"></div>
            </button>
            <div id="faq2" class="collapse" data-parent="#accordion">
              <p>
                No problem! Please click «Forgot password» on the login page and create a new password. If you need
                further help, do not hesitate to contact us by sending an email to
                <ng-container *ngIf="currentLang === 'ch'"
                  ><a href="mailto:info@medtoday.ch" draggable="false">info@medtoday.ch</a>.</ng-container
                >
                <ng-container *ngIf="currentLang === 'en'"
                  ><a href="mailto:info@medtoday.de" draggable="false">info@medtoday.de</a>.</ng-container
                >
              </p>
            </div>
          </div>
          <div class="card">
            <button
              class="btn collapsed"
              data-toggle="collapse"
              data-target="#faq3"
              aria-expanded="false"
              aria-controls="faq3"
            >
              How can I modify my personal details and my password?
              <div class="indicator"></div>
            </button>
            <div id="faq3" class="collapse" data-parent="#accordion">
              <p>
                Once you are logged in, you can change your password and your personal details in the “manage your
                profile” section.
              </p>
            </div>
          </div>
          <div class="card">
            <button
              class="btn collapsed"
              data-toggle="collapse"
              data-target="#faq4"
              aria-expanded="false"
              aria-controls="faq4"
            >
              Who can claim CME-credits on medtoday?
              <div class="indicator"></div>
            </button>
            <div id="faq4" class="collapse" data-parent="#accordion">
              <p>
                Every <ng-container *ngIf="currentLang === 'ch'">Swiss</ng-container> physician with an account on
                medtoday is able to claim CME-credits.
              </p>
            </div>
          </div>
          <div class="card">
            <button
              class="btn collapsed"
              data-toggle="collapse"
              data-target="#faq5"
              aria-expanded="false"
              aria-controls="faq5"
            >
              How can I claim CME-credits on medtoday?
              <div class="indicator"></div>
            </button>
            <div id="faq5" class="collapse" data-parent="#accordion">
              <p>
                To claim CME-credits on medtoday, a personal account with your complete name and your email address is
                required. Once you have completed your account, you will be able to take part in live-sessions and to
                earn CME-credits. To fully obtain the CME-credits, it is necessary to participate in the entire
                live-session. We do not grant partial credit points. To ensure the full attendance at a live-session, we
                track the exact moments when the participants log into their account during the CME-session.
              </p>
            </div>
          </div>
          <div class="card">
            <button
              class="btn collapsed"
              data-toggle="collapse"
              data-target="#faq6"
              aria-expanded="false"
              aria-controls="faq6"
            >
              Where can I find the accredited events?
              <div class="indicator"></div>
            </button>
            <div id="faq6" class="collapse" data-parent="#accordion">
              <p>
                Live events are indicated by a pink mark “LIVE” in the program and a pink button “Go to the stream” will
                appear. Of course, when more than one session occurs at the same time, feel free to choose. You need to
                attend the whole session (see quick facts) to claim the CME-credits
              </p>
            </div>
          </div>
          <div class="card">
            <button
              class="btn collapsed"
              data-toggle="collapse"
              data-target="#faq7"
              aria-expanded="false"
              aria-controls="faq7"
            >
              When have I passed an educational meeting?
              <div class="indicator"></div>
            </button>
            <div id="faq7" class="collapse" data-parent="#accordion">
              <p>
                You passed an educational meeting and receive the CME-credits only after completing an event. We do not
                provide partial credits. To ensure the full attendance at a live-session, we track the exact moments
                when the participants log into their account during the CME-session. To confirm your full participation
                in an event, you will receive an email with a certificate of attendance.
              </p>
            </div>
          </div>
          <div class="card">
            <button
              class="btn collapsed"
              data-toggle="collapse"
              data-target="#faq8"
              aria-expanded="false"
              aria-controls="faq8"
            >
              How many credits can I claim?
              <div class="indicator"></div>
            </button>
            <div id="faq8" class="collapse" data-parent="#accordion">
              <p>
                The amount of CME-credits is defined by the Swiss Medical Societies. It depends on the event’s duration.
                In general the societies approve 1 credit for a session of 45-60 minutes. You will find the exact amount
                of CME-credits in the quick facts of each event.
              </p>
            </div>
          </div>
          <div class="card">
            <button
              class="btn collapsed"
              data-toggle="collapse"
              data-target="#faq9"
              aria-expanded="false"
              aria-controls="faq9"
            >
              Where can I find my CME-credits?
              <div class="indicator"></div>
            </button>
            <div id="faq9" class="collapse" data-parent="#accordion">
              <p>
                You will find the credit information on your certificate of attendance which you will received by email,
                once you have completed a CME-Session.
              </p>
            </div>
          </div>
          <div class="card">
            <button
              class="btn collapsed"
              data-toggle="collapse"
              data-target="#faq11"
              aria-expanded="false"
              aria-controls="faq11"
            >
              Where can I find my certificate of attendance?
              <div class="indicator"></div>
            </button>
            <div id="faq11" class="collapse" data-parent="#accordion">
              <p>
                We will send the certificate of attendance by email. If you still have not received an email two weeks
                after an event, please check your spam. For missing certificates please contact us at
                <ng-container *ngIf="currentLang === 'ch'"
                  ><a href="mailto:info@medtoday.ch" draggable="false">info@medtoday.ch</a>.</ng-container
                >
                <ng-container *ngIf="currentLang === 'en'"
                  ><a href="mailto:info@medtoday.de" draggable="false">info@medtoday.de</a>.</ng-container
                >
              </p>
            </div>
          </div>
          <div class="card">
            <button
              class="btn collapsed"
              data-toggle="collapse"
              data-target="#faq13"
              aria-expanded="false"
              aria-controls="faq13"
            >
              Do I have to pay to attend a CME-event?
              <div class="indicator"></div>
            </button>
            <div id="faq13" class="collapse" data-parent="#accordion">
              <p>With the generous financial support of our sponsors, several events on medtoday are free of charge.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-container *ngIf="congressLandingPageData$ | async">
  <section id="sponsors">
    <div class="container">
      <h2>Sponsoren</h2>
      <hr />
      <div class="row">
        <div class="col-12 col-md-8 offset-md-4">
          <div class="row">
            <div *ngFor="let sponsor of sponsors" class="col-sm-6 col-md-4">
              <div class="sponsor">
                <img *ngIf="sponsor.logo" [src]="s3BucketUrl + logoUrl + sponsor.logo" draggable="false" alt="" />
                <p *ngIf="!sponsor.logo">
                  {{ sponsor.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-8 offset-md-4">
          <p>
            The contents of this event are not supported by the sponsors in terms of scope and form, content and
            presentation influenced.
          </p>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<app-spacer></app-spacer>
