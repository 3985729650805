import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { delay, filter, map } from 'rxjs/operators';
import { getShowCmeTrackingPromptModal } from '../medtoday-store/selectors/medtoday-store.selectors';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../../todaylib/core/store/reducers';
import { CmeTrackingTriggerStrategy } from '../classes/cme/cme-tracking-trigger-strategy';

/**
 * Its used only for DE cme tracking to display prompt before entering on channel
 */
@Injectable()
export class ChannelCmePromptGuard implements CanActivate {
  isCmePromptModalOpen$ = this.store.select(getShowCmeTrackingPromptModal);

  constructor(private store: Store<BaseAppState>, private cmeTrackingTriggerStrategy: CmeTrackingTriggerStrategy) {}

  canActivate(): Observable<boolean> {
    if (environment.language !== 'de') {
      return of(true);
    } else {
      return this.cmeTrackingTriggerStrategy.triggerCmeStateIfNeeded().pipe(
        delay(100),
        switchMap(cmeTogglePrompt => {
          if (!cmeTogglePrompt) {
            return of(true);
          } else {
            return this.isCmePromptModalOpen$.pipe(
              filter(isCmePromptModalOpen => !isCmePromptModalOpen),
              map(isCmePromptModalOpen => !isCmePromptModalOpen)
            );
          }
        })
      );
    }
  }
}
