import { AfterViewInit, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Session } from 'medtoday-models-library';
import { takeUntil } from 'rxjs/operators';

import { TimeSlotRow } from '../../models/time-slot-row';
import { SESSION_UTILS_TOKEN } from '../../utilities/session.utils';
import { ObservableComponent } from '../observable/observable.component';
import { FilterOption } from '../../modules/filters/models/filter-option.model';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent extends ObservableComponent implements AfterViewInit {
  @Input() type = '';
  @Input() showDots = false;
  @Input() filterLabel = '';
  // tslint:disable-next-line: no-any
  @Input() filterOptions: FilterOption<any>[];
  // tslint:disable-next-line: no-any
  @Input() dataSource: any;
  @Input() attributeAsButtonLabel;
  @Input() prefix = '';
  @Input() hasAllButton = false;
  @Input() hideLabel = false;
  @Input() dropdownForMobileEnabled = false;
  // we can provide a pipe for the button labels, the datepipe for example.
  // if we provide it, we're also able to provide a format for the date
  @Input() labelPipe = '';
  @Input() dateFormat = '';
  @Output() allButtonHandler = new EventEmitter();
  @Output() handleFilter = new EventEmitter();
  @Input() filterDropdownPlaceholder = '';
  // change to dropdown when certain length
  @Input() changeToDropdownIfLength: null | Number = null;

  @Input() selectedOptions: FilterOption<any>[] = [];
  public overflow = false;

  constructor(private route: ActivatedRoute, @Inject(SESSION_UTILS_TOKEN) private sessionUtils) {
    super();
  }

  ngAfterViewInit(): void {
    const row = document.getElementById('row');
    if (row) {
      const overflows = row?.clientWidth < row.scrollWidth;
      if (overflows) {
        this.overflow = true;
      }
    }
    this.handleFilterQuery();
  }

  handleSelectOption(filterOption: FilterOption<any>) {
    this.handleFilter.emit(filterOption);
  }

  handleReset() {
    this.allButtonHandler.emit();
  }

  handleFilterQuery() {
    this.route.queryParams.pipe(takeUntil(this.ngDestroy$)).subscribe(params => {
      const id = parseInt(params.filter);
      const filtered = this.filterOptions.find(item => item.id === id);

      if (filtered) {
        this.handleFilter.emit(filtered);
      }
    });
  }

  doesTopicHaveLiveSession(topicId: number) {
    return (
      this.type === 'topic' &&
      this.dataSource?.length > 0 &&
      this.dataSource?.some((row: TimeSlotRow) =>
        row.sessions.some(
          (sn: Session) =>
            sn.topic?.id === topicId &&
            this.isLive({
              ...row,
              sessions: [sn]
            })
        )
      )
    );
  }

  isLive(row: TimeSlotRow) {
    return this.sessionUtils.isActive(row);
  }
}
