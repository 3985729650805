<ng-container [ngSwitch]="currentLang">
  <ng-container *ngSwitchCase="'en'">
    <app-en-cme-congress-page></app-en-cme-congress-page>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="congressCmePageData$ | async as congressCmePageData">
      <ng-container *ngIf="congressMetaData$ | async as congressMetaData">
        <header>
          <img
            *ngIf="congressMetaData.layout.backgroundImage"
            draggable="false"
            [src]="s3BucketUrl + congressMetaData.layout.backgroundImage"
          />
          <div class="container mt-5">
            <div class="row mb-5">
              <div class="col-xl-2 col-lg-3 col-md-4 col-12">
                <app-navigation-menu></app-navigation-menu>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-7 order-lg-1 h-100 mt-4 mb-4 pr-sm-2 mb-3">
                <h1>CME-Punkte für Ihre Teilnahme an der LIVE-Veranstaltung</h1>

                <p [innerHTML]="congressCmePageData.cmeDescription"></p>

                <ng-container *ngIf="congressMetaData.menuOptions.includes('program')">
                  <button class="btn btn-primary d-flex text-white mt-5 rounded" (click)="handleGoToProgramClick()">
                    {{ 'Navigation.GoToProgramme' | translate }}
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
        </header>
      </ng-container>

      <section id="cme-tutorial">
        <div class="container">
          <h4 class="text-center mb-5">CME Punkte sammeln ist ganz einfach!</h4>
          <div class="row">
            <div class="cme-step col-sm-12 col-md-4">
              <img draggable="false" src="assets/images/CME_step-1.svg" alt="" />
              <h4>1. Toggle aktivieren</h4>
              <p>
                Aktivieren Sie den Toggle beim Betreten des Live-Streams, um Ihre Teilnahme am Live-Programm zu tracken.
              </p>
            </div>
            <div class="cme-step col-sm-12 col-md-4">
              <img draggable="false" src="assets/images/CME_step-2.svg" alt="" />
              <h4>2. EFN eintragen</h4>
              <p>
                Ohne EFN können Sie nicht teilnehmen. Ergänzen Sie daher spätestens vor Veranstaltungsbeginn Ihre EFN in
                Ihrem Profil.
              </p>
            </div>
            <div class="cme-step col-sm-12 col-md-4">
              <img draggable="false" src="assets/images/CME_step-3.svg" alt="" />
              <h4>3. Live-Programm anschauen</h4>
              <p>
                Treten Sie dem Live-Stream bei und schauen Sie sich das Programm an. Anschließend wird Ihnen die
                Teilnahmebescheinigung zugesendet.
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 d-flex justify-content-center">
              <app-efn-updater-control></app-efn-updater-control>
            </div>
          </div>
        </div>
      </section>
      <section id="quickfacts">
        <div class="container">
          <h3>Quickfacts</h3>
          <hr />
          <div class="quickfacts-vnr-container row">
            <div class="col-sm-12 col-md-4">
              <h4>VNR</h4>
            </div>

            <div class="col-sm-12 col-md-8">
              <p [innerHTML]="congressCmePageData.vnrDescription"></p>
            </div>
          </div>
          <div class="quickfacts-program-container row">
            <div class="col-sm-12 col-md-4">
              <h4>CME-Punkte für das Programm</h4>
            </div>

            <div class="col-sm-12 col-md-8">
              <p [innerHTML]="congressCmePageData.programDescription"></p>
            </div>
          </div>
          <div class="quickfacts-scientific-management-container row">
            <div class="col-sm-12 col-md-4">
              <h4>Wissenschaftliche Leitung</h4>
            </div>

            <div class="col-sm-12 col-md-8">
              <p [innerHTML]="congressCmePageData.experts"></p>
            </div>
          </div>
          <div class="quickfacts-speakers-container row">
            <div class="col-sm-12 col-md-4">
              <h4>Referent:innen</h4>
            </div>
            <div class="col-sm-12 col-md-8">
              <p>
                Alle Referent:innen dieser Live-Veranstaltung inklusive ihrer Interessenskonflikte finden Sie in unserer
                <a (click)="handleGoToExpertsClick()">Übersicht</a>.
              </p>
            </div>
          </div>
          <ng-container *ngIf="congressMetaData$ | async as congressMetaData">
            <ng-container *ngIf="congressMetaData.menuOptions.includes('program')">
              <button class="btn btn-primary d-flex text-white mt-5 rounded" (click)="handleGoToProgramClick()">
                {{ 'Navigation.GoToProgramme' | translate }}
              </button>
            </ng-container>
          </ng-container>
        </div>
      </section>
      <section id="cme-faq">
        <div class="container">
          <h2>Frequently Asked Questions</h2>
          <hr />
          <div class="row">
            <div class="col-12 col-md-8 offset-md-4 mb-4">
              <div id="accordion">
                <div class="card">
                  <button
                    class="btn collapsed"
                    data-toggle="collapse"
                    data-target="#faq1"
                    aria-expanded="true"
                    aria-controls="faq1"
                  >
                    Muss ich mich auf medtoday registrieren, um CME-Punkte zu sammeln?
                    <div class="indicator"></div>
                  </button>
                  <div id="faq1" class="collapse" data-parent="#accordion">
                    <p>
                      Ja. Sie müssen sich auf medtoday registrieren, um CME-Punkte sammeln zu können. Hierfür erstellen
                      Sie mit Ihrer E-Mail-Adresse und einem von Ihnen gewählten Passwort Ihren persönlichen Account. Im
                      Anschluss ist es notwendig, dass Sie im Bereich „Profil verwalten“ Ihre EFN-Nummer unter
                      persönliche Daten ergänzen. Ohne Ihre EFN-Nummer, werden Sie vom System nicht als Teilnehmer für
                      CME-Punkte erfasst.
                    </p>
                  </div>
                </div>
                <div class="card">
                  <button
                    class="btn collapsed"
                    data-toggle="collapse"
                    data-target="#faq2"
                    aria-expanded="false"
                    aria-controls="faq2"
                  >
                    Ich habe meine Zugangsdaten vergessen. Was muss ich tun?
                    <div class="indicator"></div>
                  </button>
                  <div id="faq2" class="collapse" data-parent="#accordion">
                    <p>
                      Kein Problem. Sie können auf der Login-Seite auf „Passwort vergessen?“ klicken und so ein neues
                      Passwort erstellen. Wenn Sie weitere Hilfe benötigen, dann kontaktieren Sie uns unter
                      <a href="mailto:info@medtoday.de" draggable="false">info@medtoday.de</a>.
                    </p>
                  </div>
                </div>
                <div class="card">
                  <button
                    class="btn collapsed"
                    data-toggle="collapse"
                    data-target="#faq3"
                    aria-expanded="false"
                    aria-controls="faq3"
                  >
                    Wie ändere ich meine personenbezogenen Daten und mein Passwort?
                    <div class="indicator"></div>
                  </button>
                  <div id="faq3" class="collapse" data-parent="#accordion">
                    <p>
                      Sobald Sie eingeloggt sind, können Sie im Bereich „Profil verwalten“ sowohl Ihre persönlichen
                      Daten anpassen als auch Ihr Passwort ändern.
                    </p>
                  </div>
                </div>
                <div class="card">
                  <button
                    class="btn collapsed"
                    data-toggle="collapse"
                    data-target="#faq4"
                    aria-expanded="false"
                    aria-controls="faq4"
                  >
                    Wer kann auf medtoday CME-Punkte sammeln?
                    <div class="indicator"></div>
                  </button>
                  <div id="faq4" class="collapse" data-parent="#accordion">
                    <p>
                      Jeder Arzt und jede Ärztin mit einer EFN-Nummer kann bei uns CME-Punkte sammeln. Sie müssen
                      hierfür nur einen Account erstellen und Ihre EFN-Nummer angeben.
                    </p>
                  </div>
                </div>
                <div class="card">
                  <button
                    class="btn collapsed"
                    data-toggle="collapse"
                    data-target="#faq5"
                    aria-expanded="false"
                    aria-controls="faq5"
                  >
                    Wie kann ich auf medtoday CME-Punkte erhalten?
                    <div class="indicator"></div>
                  </button>
                  <div id="faq5" class="collapse" data-parent="#accordion">
                    <p>
                      Um CME-Punkte auf medtoday sammeln zu können, benötigen Sie einen persönlichen Account, bei dem
                      Sie Ihre EFN und ein paar weitere Informationen angeben müssen. Sobald Sie einen Account haben,
                      können Sie an Live-Veranstaltungen teilnehmen und währenddessen für ausgewählte Programpunkte
                      CME-Punkte sammeln. Um die angegebenen CME-Punkte zu erhalten, müssen Sie an der gesamten
                      Veranstaltung teilnehmen. Um dies zu gewährleisten, tracken wir die Teilnehmer:innen mit Betreten
                      des Live-Streams und Zustimmung des Trackings durch Aktivierung eines Toggles. Es werden keine
                      Teilpunkte vergeben.
                    </p>
                  </div>
                </div>
                <div class="card">
                  <button
                    class="btn collapsed"
                    data-toggle="collapse"
                    data-target="#faq6"
                    aria-expanded="false"
                    aria-controls="faq6"
                  >
                    Wo finde ich aktuelle CME-Veranstaltungen?
                    <div class="indicator"></div>
                  </button>
                  <div id="faq6" class="collapse" data-parent="#accordion">
                    <p>
                      Die CME-Veranstaltungen finden Sie auf der medtoday Startseite in der Menüleiste über den Punkt
                      CME. Hier finden Sie gelistet die kommenden Veranstaltungen, für die wir CME-Punkte bei der
                      Ärztekammer beantragt haben. Von dort aus gelangen Sie weiter auf die kongressspezifische
                      CME-Infoseite mit weiteren Informationen und dem Programm. Sie erkennen die CME relevanten
                      Sessions im Programm des jeweiligen Kongresses anhand einer CME-Markierung. Bei parallel
                      stattfindenden Sessions können Sie zwischen den Sessions wählen. Wichtig ist, dass Sie im gesamten
                      angegebenen Zeitraum (siehe Quickfacts auf der kongressspezifischen CME-Infoseite) die
                      Live-Veranstaltung besuchen, um die CME-Punkte zu erhalten.
                    </p>
                  </div>
                </div>
                <div class="card">
                  <button
                    class="btn collapsed"
                    data-toggle="collapse"
                    data-target="#faq7"
                    aria-expanded="false"
                    aria-controls="faq7"
                  >
                    Wann habe ich die Fortbildung bestanden?
                    <div class="indicator"></div>
                  </button>
                  <div id="faq7" class="collapse" data-parent="#accordion">
                    <p>
                      Sie haben die Fortbildung bestanden und erhalten die angegebenen CME-Punkte, wenn Sie an der
                      gesamten Veranstaltung teilgenommen haben. Es werden keine Teilpunkte vergeben. Um dies zu
                      gewährleisten, tracken wir die Teilnehmer:innen während der CME-Veranstaltung mit Betreten des
                      Live-Streams und Zustimmung des Trackings durch Aktivierung eines Toggles. Als Bestätigung Ihrer
                      erfolgreichen Teilnahme wird Ihnen nach der Veranstaltung eine Teilnahmebescheinigung per E-Mail
                      zugesendet.
                    </p>
                  </div>
                </div>
                <div class="card">
                  <button
                    class="btn collapsed"
                    data-toggle="collapse"
                    data-target="#faq8"
                    aria-expanded="false"
                    aria-controls="faq8"
                  >
                    Wie viele Punkte bekomme ich?
                    <div class="indicator"></div>
                  </button>
                  <div id="faq8" class="collapse" data-parent="#accordion">
                    <p>
                      Die Anzahl der CME-Punkte wird von der Ärztekammer festgelegt. Sie variiert pro Veranstaltung, da
                      pro 45-Minuten-Einheit 1 Punkt vergeben werden kann. Die genaue CME-Punkteanzahl finden Sie in den
                      Quickfacts auf der kongressspezifischen CME-Infoseite.
                    </p>
                  </div>
                </div>
                <div class="card">
                  <button
                    class="btn collapsed"
                    data-toggle="collapse"
                    data-target="#faq9"
                    aria-expanded="false"
                    aria-controls="faq9"
                  >
                    Wo finde ich die Anzahl meiner gesammelten CME-Punkte?
                    <div class="indicator"></div>
                  </button>
                  <div id="faq9" class="collapse" data-parent="#accordion">
                    <p>
                      Die Anzahl der gesammelten Punkte pro Veranstaltung finden Sie auf der Teilnahmebescheinigung, die
                      Ihnen nach jeder erfolgreich teilgenommenen Veranstaltung per E-Mail zugesendet wird.
                    </p>
                  </div>
                </div>
                <div class="card">
                  <button
                    class="btn collapsed"
                    data-toggle="collapse"
                    data-target="#faq10"
                    aria-expanded="false"
                    aria-controls="faq10"
                  >
                    Wie lange dauert die Übermittlung meiner CME-Punkte an die Ärztekammer?
                    <div class="indicator"></div>
                  </button>
                  <div id="faq10" class="collapse" data-parent="#accordion">
                    <p>
                      Wir als Veranstalter werden alle erfolgreichen Teilnahmen innerhalb von 14 Tagen nach Ende der
                      Veranstaltung an die Bundesärztekammer übermitteln. Bitte bewahren Sie Ihre
                      Teilnahmebescheinigung, die Sie von uns erhalten, auf, falls Rückfragen zur Teilnahme von der
                      Ärztekammer an Sie gerichtet werden.
                    </p>
                  </div>
                </div>
                <div class="card">
                  <button
                    class="btn collapsed"
                    data-toggle="collapse"
                    data-target="#faq11"
                    aria-expanded="false"
                    aria-controls="faq11"
                  >
                    Wo finde ich meine Teilnahmebescheinigung?
                    <div class="indicator"></div>
                  </button>
                  <div id="faq11" class="collapse" data-parent="#accordion">
                    <p>
                      Die Teilnahmebescheinigung, die belegt, dass Sie erfolgreich an der Veranstaltung teilgenommen
                      haben, wird von uns, sobald wir die finale Rückmeldung zur Anerkennung durch die Ärztekammer
                      erhalten haben, per E-Mail an Sie versendet. Sollten Sie auch zwei Wochen nach der Veranstaltung
                      noch keine E-Mail mit der Bescheinigung von uns erhalten haben, dann prüfen Sie bitte Ihren
                      Spam-Ordner. Falls auch dort die E-Mail nicht zu finden ist, kontaktieren Sie uns bitte über
                      <a href="mailto:info@medtoday.de" draggable="false">info@medtoday.de</a>.
                    </p>
                  </div>
                </div>
                <div class="card">
                  <button
                    class="btn collapsed"
                    data-toggle="collapse"
                    data-target="#faq12"
                    aria-expanded="false"
                    aria-controls="faq12"
                  >
                    Wer zertifiziert die Fortbildung?
                    <div class="indicator"></div>
                  </button>
                  <div id="faq12" class="collapse" data-parent="#accordion">
                    <p>
                      Für unsere CME-Veranstaltungen wurden CME-Punkte bei der Ärztekammer Berlin beantragt. Die
                      Veranstaltung entspricht somit den Qualitätsanforderungen an mediengestützte Fortbildungsmaßnahmen
                      gemäß den „Empfehlungen zur ärztlichen Fortbildung“ der Bundesärztekammer.
                    </p>
                  </div>
                </div>
                <div class="card">
                  <button
                    class="btn collapsed"
                    data-toggle="collapse"
                    data-target="#faq13"
                    aria-expanded="false"
                    aria-controls="faq13"
                  >
                    Ist die Teilnahme an den CME-Veranstaltungen kostenfrei?
                    <div class="indicator"></div>
                  </button>
                  <div id="faq13" class="collapse" data-parent="#accordion">
                    <p>
                      Durch die Unterstützung unserer Sponsoren sind alle Veranstaltungen auf medtoday für die
                      Teilnehmer:innen komplett kostenlos.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ng-container *ngIf="congressLandingPageData$ | async">
        <section id="sponsors">
          <div class="container">
            <h2>Sponsoren</h2>
            <hr />
            <div class="row">
              <div class="col-12 col-md-8 offset-md-4">
                <div class="row">
                  <div *ngFor="let sponsor of sponsors" class="col-sm-6 col-md-4">
                    <div class="sponsor">
                      <img *ngIf="sponsor.logo" [src]="s3BucketUrl + logoUrl + sponsor.logo" draggable="false" alt="" />
                      <p *ngIf="!sponsor.logo">
                        {{ sponsor.name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-8 offset-md-4">
                <p [innerHTML]="congressCmePageData.sponsorAmounts"></p>
              </div>
            </div>
          </div>
        </section>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<app-spacer></app-spacer>
